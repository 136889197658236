<template>
  <product-section>
    <section class="section buckethound-section">
      <div class="logo">
        <img src="../../assets/buckethound-logo.svg">
      </div>
      <div class="columns is-flex">
        <div class="column info-column">
          <div class="container">
            <div class="content">
              <h1 class="title has-text-white">Scouring The Internet For Data</h1>
              <p class="has-text-white">Buckethound leverages public datasets and a scanning infrastructure to discover S3 (and S3-compatible) buckets, then analyze and index their contents. This platform is invite-only given the sensitive nature of the data contained, but we have plans to make this more of an open initiative for alerting data owners to potential leaks.</p>
              <div class="buttons is-centered">
                <a href="https://buckethound.calidog.io/" target="_blank" class="button is-link is-outlined is-inverted"><b>Visit Buckethound</b></a>
              </div>
            </div>
          </div>
        </div>
        <div class="column video-column">
          <div class="box is-paddingless">
            <video autoplay muted controls loop src="../../assets/video/buckethound.mp4"></video>
          </div>
        </div>
      </div>
    </section>
  </product-section>
</template>

<script>
import ProductSection from "./_Section";
export default {
  name: "BuckethoundSection",
  components: {ProductSection},
  methods: {},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .buckethound-section{
    background: $buckethound-blue;
    .logo{
      margin-bottom: 0 !important;
      margin-top: -1rem;
    }
    .button.is-link{
      color: $buckethound-blue;
    }
  }
</style>
