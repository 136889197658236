<template>
  <section class="section main-section is-paddingless">
    <div class="columns is-fullwidth">
      <div class="column left-column">
        <calidog-info />
      </div>
      <div class="column is-flex right-column animated fadeInDown">
        <div class="box-wrapper">
          <phishfinder-panel @scroll-to="scrollToSection($event)" />
          <certstream-panel @scroll-to="scrollToSection($event)" />
          <buckethound-panel @scroll-to="scrollToSection($event)" />
          <scantastic-panel @scroll-to="scrollToSection($event)" />
          <snifferizer-panel @scroll-to="scrollToSection($event)" />
        </div>
      </div>
    </div>
  </section>
  <phishfinder-section />
  <certstream-section />
  <buckethound-section />
  <scantastic-section />
  <snifferizer-section />
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill';

import PanelComponents from './components/panels';
import CalidogInfo from "./components/CalidogInfo";
import PhishfinderSection from "./components/sections/PhishfinderSection";
import CertstreamSection from "./components/sections/CertstreamSection";
import BuckethoundSection from "./components/sections/BuckethoundSection";
import ScantasticSection from "./components/sections/ScantasticSection";
import SnifferizerSection from "./components/sections/SnifferizerSection";

export default {
  name: 'App',
  components: {
    SnifferizerSection,
    ScantasticSection,
    BuckethoundSection,
    CertstreamSection,
    PhishfinderSection,
    CalidogInfo,
    ...PanelComponents
  },
  mounted(){
    smoothscroll.polyfill();
  },
  methods: {
    scrollToSection: (selector) =>{
      document.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style lang="scss" type="text/scss">
  @import 'node_modules/bulma/bulma';
  @import './../node_modules/animate.css/source/animate.css';

  @import './assets/scss/colors';

  .main-section{
    @include from($tablet) {
      height: 100vh;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    background: $calidog-red;
    > .columns{
      width: 100%;
    }
    .column{
      @include from($tablet) {
        height: 100vh;
      }
      display: flex;
      align-items: center;
    }
    .left-column{
      justify-content: center;
      flex-direction: column;
      @include until($tablet){
        margin-top: 2rem;
      }
    }
    .right-column{
      display: flex;
      justify-content: center;
      flex-direction: column;
      @include until($tablet) {
        animation-name: fadeIn;
      }
      .box-wrapper{
        padding: 1.6rem .5rem;
        @include from($tablet){
          padding: 1rem 1.6rem 3rem;
          max-height: 100vh;
        }
        overflow-y: scroll;
      }
    }
  }
</style>
