<template>
  <panel>
    <div @click="$emit('scroll-to', '.phishfinder-section')" class="box panel phishfinder">
      <div class="content">
        <div class="project">
          <img src="../../assets/phishfinder.png">
          <div class="content">
            <p class="has-text-white">
              <strong class="has-text-white">Phishfinder</strong>
              <br>
              Phishfinder makes phishing protection affordable to any organization, regardless of its size!
            </p>
          </div>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from './_Panel'

export default {
  name: "PhishfinderPanel",
  components: {Panel},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .panel.phishfinder{
    background: $phishfinder-blue;
  }
</style>
