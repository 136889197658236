<template>
  <panel>
    <div @click="$emit('scroll-to', '.snifferizer-section')" class="box panel snifferizer">
      <coming-soon-banner />
      <div class="content">
        <div class="project">
          <img src="../../assets/snifferizer.png">
          <div class="content">
            <p class="has-text-white">
              <strong class="has-text-white">Snifferizer</strong>
              <br>
              Snifferizer is a real-time passive signals intelligence and analysis platform that can be used for home or business security, as well as for executive protection details.
            </p>
          </div>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from './_Panel'
import ComingSoonBanner from "../ComingSoonBanner";

export default {
  name: "SnifferizerPanel",
  components: {ComingSoonBanner, Panel},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .panel.snifferizer{
    background: $snifferizer-red;
    .content{
      padding-right: 1rem;
    }
  }
</style>
