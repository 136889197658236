import Panel from "./_Panel";

import BuckethoundPanel from "./BuckethoundPanel";
import CertstreamPanel from "./CertstreamPanel";
import PhishfinderPanel from "./PhishfinderPanel";
import ScantasticPanel from "./ScantasticPanel";
import SnifferizerPanel from "./SnifferizerPanel";

export default {
  Panel,
  BuckethoundPanel,
  CertstreamPanel,
  PhishfinderPanel,
  ScantasticPanel,
  SnifferizerPanel,
}
