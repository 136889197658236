<template>
  <div class="calidog-info animated fadeInUp">
    <img class="doghead" src="../assets/doghead.png">
    <h1 class="title">Cali Dog Security</h1>
    <h1 class="subtitle">Making Security Ubiquitous</h1>
    <div class="contact">
      <a class="contact-button twitter" target="_blank" href="https://twitter.com/calidogsec">
        <img class="logo" src="../assets/twitter.svg">
      </a>
      <a class="contact-button email" target="_blank" href="mailto:hello@calidog.io">
        <img class="logo" src="../assets/email.svg">
      </a>
      <a class="contact-button blog" target="_blank" href="https://medium.com/cali-dog-security">
        <img class="logo" src="../assets/text.svg">
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CalidogInfo",
    methods: {},
  }
</script>

<style lang="scss" type="text/scss">
  @import 'node_modules/bulma/bulma';
  .calidog-info{
    padding: 1rem;
    text-align: center;
    border-radius: 10px;
    transition: all 0.5s cubic-bezier(.25,.8,.25,1);
    @include until($tablet) {
      animation-name: fadeIn !important;
    }
    h1{
      color: white;
    }
    img.doghead{
      max-width: 15vw;
      margin-bottom: 1rem;
      @include until($tablet) {
        max-width: 30vw;
      }
      @include until(390px) {
        max-width: 45vw;
      }
      filter: drop-shadow(0px 1px 3px rgba(0,0,0,0.12)) drop-shadow(0px 1px 2px rgba(0,0,0,0.24));
      transition: all 0.5s cubic-bezier(.25,.8,.25,1);
      &:hover {
        filter: drop-shadow(0px 7px 14px rgba(0,0,0,0.25)) drop-shadow(0px 5px 5px rgba(0,0,0,0.22));
      }
    }
    .contact{
      display: flex;
      justify-content: center;
      .contact-button{
        padding: 0.5rem;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-items: center;
        width: 2.5rem;
        height: 2.5rem;
        transition: all 0.5s cubic-bezier(.25,.8,.25,1);
        &:not(:last-child){
          margin-right: 1.5rem;
        }
        &:hover{
          box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        }
        &:active{
          box-shadow: 0 2px 3px rgba(0,0,0,0.12), 0 1px 4px rgba(0,0,0,0.24);
        }
        .logo{
          width: 100%;
          height: 100%;
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
      }
    }
  }
</style>
