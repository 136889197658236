<template>
  <div class="product-section">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "ProductSection",
    methods: {},
  }
</script>

<style lang="scss" type="text/scss">
  @import 'node_modules/bulma/sass/utilities/all';
  .product-section{
    section{
      //min-height: 80vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      &.flipped{
        .columns{
          flex-direction: row-reverse;
        }
      }
      @include until($desktop){
        .video-column{
          padding: 1rem !important;
        }
        .columns{
          flex-direction: column-reverse;
        }
        &.flipped .columns{
          flex-direction: column-reverse;
        }
        .info-column{
          width: auto;
        }
      }
      @include until($tablet){
        .video-column, .info-column{
          padding: 1rem !important;
        }
      }
      @include from($desktop){
        .info-column, .video-column{
          width: 50%;
        }
      }
      code{
        color: rgba(0, 0, 0, 0.85);
      }
      .columns{
        align-items: center;
        .info-column{
          padding: 1.5rem 3rem;
          max-width: 100vw;
        }
      }
      .logo{
        margin-bottom: 3rem;
      }
      video{
        display: block;
        border-radius: 5px;
        transform: scale(1.01); // Hack to remove occasional border/artifact from video containers
      }
      pre{
        white-space: pre-line;
      }
    }
  }
</style>
