<template>
  <product-section>
    <section class="section scantastic-section flipped">
      <div class="logo">
        <img src="../../assets/scantastic-logo.svg">
      </div>
      <div class="columns is-flex">
        <div class="column info-column">
          <div class="container">
            <div class="content">
              <h1 class="title has-text-white">Scan all the things!</h1>
              <p class="has-text-white">
                Scantastic is your one-stop shop for all things scanning and analysis, providing simple APIs for capturing screenshots, examining rendered HTML, doing bulk DNS queries, and more!
              </p>
              <p class="has-text-white">We'll be soft-launching later this year with a select group of folks, watch this space or email us if you're interested in participating!</p>
              <div class="buttons is-centered">
                <button disabled class="button is-white is-outlined"><b>Visit Scantastic (coming soon!)</b></button>
              </div>
            </div>
          </div>
        </div>
        <div class="column video-column">
          <div class="box is-paddingless">
            <img src="../../assets/scantastic-screenshot.png">
          </div>
        </div>
      </div>
    </section>
  </product-section>
</template>

<script>
import ProductSection from "./_Section";
export default {
  name: "ScantasticSection",
  components: {ProductSection},
  methods: {},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .scantastic-section{
    background: $scantastic-green;
    .logo{
      max-width: 40rem;
    }
    .robot-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .robot{
      max-width: 10rem;
    }
    .video-column .box{
      display: flex;
      align-items: flex-start;
      img {
        border-radius: 5px;
        display: block;
      }
    }
  }
</style>
