<template>
  <product-section>
    <section class="section certstream-section flipped">
      <div class="logo">
        <img src="../../assets/certstream-logo.svg">
      </div>
      <div class="columns is-flex">
        <div class="column info-column">
          <div class="container">
            <div class="content">
              <h1 class="title has-text-white">Real Time Certificate Transparency Stream</h1>
              <p class="has-text-white">Certstream is an aggregation service, multiplexer, and collection of easy-to-use libraries to interact with the <a class="has-text-white" href="https://www.certificate-transparency.org/what-is-ct">Certificate Transparency</a> network, allowing you to react to SSL certificates as they're issued in real time. It's an amazing resource for finding phishing domains, and the libraries we provide get you up and running in seconds!</p>
              <div class="buttons is-centered">
                <a href="https://certstream.calidog.io/" target="_blank" class="button is-success is-outlined is-inverted"><b>Visit Certstream</b></a>
              </div>
<!--              <p class="has-text-white">You can even install the certstream cli (with <code>pip install certstream</code>) and interact with the stream with utilities like <strong class="has-text-white">jq</strong> and <strong class="has-text-white">grep</strong>! Easily grep for interesting domains with something like this snippet, which looks for any domains with the string "paypal" in them:</p>-->
<!--              <pre>$ certstream &#45;&#45;json | jq -r '.data.leaf_cert.all_domains[]' | grep paypal</pre>-->
            </div>
          </div>
        </div>
        <div class="column video-column">
          <div class="box is-paddingless">
            <video autoplay muted controls loop src="../../assets/video/certstream.mp4"></video>
          </div>
        </div>
      </div>
    </section>
  </product-section>
</template>

<script>
import ProductSection from "./_Section";
export default {
  name: "CertstreamSection",
  components: {ProductSection},
  methods: {},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .certstream-section{
    background: $certstream-green;
    .logo{
      margin-top: .25rem;
    }
    .content a.has-text-white{
      font-weight: 800;
      border-bottom: 1px solid transparent;
      &:hover {
        color: white !important;
        border-bottom: 1px solid white;
      }
    }
    .button.is-success{
      color: $certstream-green;
    }
  }
</style>
