<template>
  <panel>
    <div @click="$emit('scroll-to', '.certstream-section')" class="box panel certstream">
      <div class="content">
        <div class="project">
          <img src="../../assets/certstream.png">
          <div class="content">
            <p class="has-text-white">
              <strong class="has-text-white">Certstream</strong>
              <br>
              Certstream lets you monitor the Certificate Transparency Log network in real time, reacting to certificates as they're issued.
            </p>
          </div>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from './_Panel'

export default {
  name: "CertstreamPanel",
  components: {Panel},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .panel.certstream{
    background: $certstream-green;
  }
</style>
