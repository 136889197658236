<template>
  <panel>
    <div @click="$emit('scroll-to', '.buckethound-section')" class="box panel buckethound">
      <div class="content">
        <div class="project">
          <img src="../../assets/buckethound.png">
          <div class="content">
            <p class="has-text-white">
              <strong class="has-text-white">Buckethound</strong>
              <br>
              Buckethound is a crawling and classification system to discover and analyze S3 and S3-compatible storage systems, leveraging real world datasets and mutating results to discover new buckets.
            </p>
          </div>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from './_Panel'

export default {
  name: "BuckethoundPanel",
  components: {Panel},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .panel.buckethound{
    background: $buckethound-blue;
    img{
      margin-right: 1rem;
      margin-left: .5rem;
    }
  }
</style>
