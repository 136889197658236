<template>
  <panel>
    <div @click="$emit('scroll-to', '.scantastic-section')" class="box panel scantastic">
      <coming-soon-banner />
      <div class="content">
        <div class="project">
          <img src="../../assets/scantastic.png">
          <div class="content">
            <p class="has-text-white">
              <strong class="has-text-white">Scantastic</strong>
              <br>
              Scantastic is your one-stop shop for all things scanning and analysis. We're not here to tell you what to do with your data, we're just here to get you data, stat!
            </p>
          </div>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import Panel from './_Panel'
import ComingSoonBanner from "../ComingSoonBanner";

export default {
  name: "ScantasticPanel",
  components: {ComingSoonBanner, Panel},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .panel.scantastic{
    background: $scantastic-green;
    .content{
      padding-right: 1rem;
    }
  }
</style>
