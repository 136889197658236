<template>
  <slot></slot>
</template>

<script>
export default {
  name: "Panel"
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .panel.box{
    width: 100%;
    padding: 0.75rem;
    box-shadow: 0 2px 3px rgba(0,0,0,0.12), 0 1px 4px rgba(0,0,0,0.24);
    transition: all 0.5s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
    position: relative;
    .project{
      display: flex;
      align-items: center;
      .content{
        user-select: none;
      }
    }
    &:hover{
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    &:active{
      box-shadow: 0 2px 3px rgba(0,0,0,0.12), 0 1px 4px rgba(0,0,0,0.24);
    }
    img{
      max-width: 3.5rem;
      max-height: 3.5rem;
      margin-right: 1rem;
    }
  }
</style>
