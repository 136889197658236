<template>
  <product-section>
    <section class="section phishfinder-section">
      <div class="logo">
        <img src="../../assets/phishfinder-logo2.svg">
      </div>
      <div class="columns is-flex">
        <div class="column info-column">
          <div class="container">
            <div class="content">
              <h1 class="title has-text-white">Affordable Phishing Protection For Everyone</h1>
              <p class="has-text-white">Phishing affects every organization on the planet, and yet there don't seem to be any good solutions to help combat it unless you have thousands of dollars a month to spend. We built Phishfinder because we wanted to equip anyone who needs it with tools to monitor for and react to phishing as it's happening.</p>
              <div class="buttons is-centered">
                <a href="https://phishfinder.io/" target="_blank" class="button is-link is-outlined is-inverted"><b>Visit Phishfinder</b></a>
              </div>
              <p class="has-text-centered">
                <img src="../../assets/phishfinder-gull.svg">
              </p>
            </div>
          </div>
        </div>
        <div class="column video-column">
          <div class="box is-paddingless">
            <video autoplay muted controls loop src="../../assets/video/phishfinder.mp4"></video>
          </div>
        </div>
      </div>
    </section>
  </product-section>
</template>

<script>
import ProductSection from "./_Section";
export default {
  name: "PhishfinderSection",
  components: {ProductSection},
  methods: {},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .phishfinder-section{
    background: $phishfinder-blue;
    .button.is-link{
      color: $phishfinder-blue
    }
  }
</style>
