<template>
  <product-section>
    <section class="section snifferizer-section">
      <div class="logo">
        <img src="../../assets/snifferizer-logo.svg">
      </div>
      <div class="columns is-flex">
        <div class="column info-column">
          <div class="container">
            <div class="content">
              <h1 class="title has-text-white">Sniffing For Actionable Intelligence</h1>
              <p class="has-text-white">Snifferizer is a platform for real time passive signals analysis and correlation. Coming soon!</p>
              <div class="buttons is-centered">
                <button disabled class="button is-white is-outlined"><b>Visit Snifferizer (coming soon!)</b></button>
              </div>
              <div class="has-text-centered">
                <img src="../../assets/snifferizer-dog.png">
              </div>
            </div>
          </div>
        </div>
        <div class="column video-column">
          <div class="box is-paddingless">
            <img src="../../assets/snifferizer-screenshot.png">
          </div>
        </div>
      </div>
    </section>
  </product-section>
</template>

<script>
import ProductSection from "./_Section";
export default {
  name: "SnifferizerSection",
  components: {ProductSection},
  methods: {},
}
</script>

<style lang="scss" type="text/scss">
  @import '../../assets/scss/colors';

  .snifferizer-section{
    background: $snifferizer-red;
    .logo{
      max-width: 40rem;
    }
    .video-column .box{
      display: flex;
      align-items: flex-start;
      img {
        border-radius: 5px;
        display: block;
      }
    }
  }
</style>
