<template>
  <div class="ribbon ribbon-top-right">
    <span>
      <strong class="has-text-white">
        Coming Soon!
      </strong>
    </span>
  </div>
</template>

<script>
export default {
  name: "ComingSoonBanner"
}
</script>

<style lang="scss" type="text/scss">
  @import '../assets/scss/colors';
  .ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
    &::before, &::after{
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 5px solid darken($buckethound-blue, 10%);
    }
    &.ribbon-top-right {
      top: -10px;
      right: -10px;
      &::before, &::after{
        border-top-color: transparent;
        border-right-color: transparent;
      }
      &::before{
        top: 0;
        left: 0;
      }
      &::after{
        bottom: 0;
        right: 0;
      }
      span{
        left: -25px;
        top: 30px;
        transform: rotate(45deg);
      }
    }
    span{
      position: absolute;
      display: block;
      width: 175px;
      padding: 5px 0;
      background-color: $buckethound-blue;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
      color: #fff;
      text-align: center;
      font-size: 0.75rem;
    }
  }
</style>
